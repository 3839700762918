/* eslint-disable react-hooks/exhaustive-deps */
import "react-toastify/dist/ReactToastify.css";
import "@/styles/globals.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import type { AppProps } from "next/app";
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";

// toast
import { ToastContainer } from "react-toastify";

//contexts
import UserContext from "@/context/UserContext";
import ProjectContext from "@/context/ProjectContext";
import StepsContext from "@/context/StepContext";
import PixValueContext from "@/context/PixValueContext";
import PixKeyContext from "@/context/PixKeyContext";
import WalletContext from "@/context/WalletContext";

import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "../next-i18next.config.js";
import localFont from "@next/font/local";

const poppins = localFont({
	src: [
		{
			path: "../assets/fonts/Poppins-Thin.ttf",
			weight: "100",
		},
		{
			path: "../assets/fonts/Poppins-ExtraLight.ttf",
			weight: "200",
		},
		{
			path: "../assets/fonts/Poppins-Light.ttf",
			weight: "400",
		},
		{
			path: "../assets/fonts/Poppins-Bold.ttf",
			weight: "700",
		},
	],
	variable: "--font-poppins",
});

function App({ Component, pageProps }: any) {
	const [isAdmin, setIsAdmin] = useState(false);
	const [userInfo, setUserInfo] = useState();
	const [loggedIn, setLoggedIn] = useState(false);
	const [projectSelectedContext, setProjectSelectedContext] =
		React.useState<any>();
	const [arrayVerify, setArrayVerify] = React.useState<any>([]);
	const [approvedInternational, setApprovedInternational] = useState<boolean>();
	const [rejectedInternational, setRejectedInternational] =
		useState<boolean>(false);
	const [nextStepRegister, setNextStepRegister] = useState<boolean>(false);
	const [documentSelected, setDocumentSelected] = React.useState<string>("");
	const [steps, setSteps] = useState<number>(2);
	const [pixValue, setPixValue] = useState("");
	const [pixKey, setPixKey] = useState("");
	const router = useRouter();
	const { locale } = router;
	const [showWallet, setShowWallet] = useState<boolean>(true);
	const [stageWallet, setStageWallet] = useState<number>(0);

	return (
		<PixKeyContext.Provider
			value={{
				pixKey,
				setPixKey,
			}}
		>
			<PixValueContext.Provider
				value={{
					pixValue,
					setPixValue,
				}}
			>
				<ProjectContext.Provider
					value={{
						projectSelectedContext,
						setProjectSelectedContext,
					}}
				>
					<StepsContext.Provider
						value={{
							steps,
							setSteps,
						}}
					>
						<WalletContext.Provider
							value={{
								showWallet,
								setShowWallet,
								stageWallet,
								setStageWallet,
							}}
						>
							<UserContext.Provider
								value={{
									userAdmin: [isAdmin, setIsAdmin],
									userInfo: [userInfo, setUserInfo],
									loggedIn: [loggedIn, setLoggedIn],
									locale,
									setApprovedInternational,
									approvedInternational,
									rejectedInternational,
									setRejectedInternational,
									nextStepRegister,
									setNextStepRegister,
									documentSelected,
									setDocumentSelected,
								}}
							>
								<ToastContainer
									autoClose={2500}
									bodyStyle={{
										background: "#333",
										color: "red",
										padding: "10px",
										borderRadius: "5px",
									}}
								/>
								<main className={poppins.className}>
									<Component {...pageProps} />
								</main>
							</UserContext.Provider>
						</WalletContext.Provider>
					</StepsContext.Provider>
				</ProjectContext.Provider>
			</PixValueContext.Provider>
		</PixKeyContext.Provider>
	);
}
export async function getServerSideProps() {
	const {
		NEXT_PUBLIC_PROJETO_URL,
		NEXT_PUBLIC_PROJETO_ID_URL,
		NEXT_PUBLIC_GET_USER_INFO,
		NEXT_PUBLIC_GET_TRANSACTIONS,
		NEXT_PUBLIC_GERAR_PIX,
		NEXT_PUBLIC_COMPRAR_TOKEN,
		NEXT_PUBLIC_REQUEST_LOGIN,
		NEXT_PUBLIC_CADASTRAR_USER,
		NEXT_PUBLIC_GET_USER_CADASTRO,
		NEXT_PUBLIC_AUTH_LOGIN,
		NEXT_PUBLIC_UPLOAD_PROFILE_PHOTO,
		NEXT_PUBLIC_UPLOAD_DOCUMENTS_PROJETO,
		NEXT_PUBLIC_UPLOAD_BACKGROUND_PHOTO,
		NEXT_PUBLIC_CRIAR_PROJETO,
		NEXT_PUBLIC_UPLOAD_BACKGROUND_PROJETO,
		NEXT_PUBLIC_VERIFICATION_USER,
		NEXT_PUBLIC_SEND_EMAIL_FINISHED,
		NEXT_PUBLIC_SEND_EMAIL_APPROVED,
		NEXT_PUBLIC_SEND_EMAIL_REJECTED,
		NEXT_PUBLIC_VERIFICATION_ID_METAMAP,
		NEXT_PUBLIC_ID_GOOGLE_ANALYTIC,
	} = process.env;
	const envs = {
		NEXT_PUBLIC_AUTH_LOGIN: NEXT_PUBLIC_AUTH_LOGIN,
		NEXT_PUBLIC_CADASTRAR_USER: NEXT_PUBLIC_CADASTRAR_USER,
		NEXT_PUBLIC_COMPRAR_TOKEN: NEXT_PUBLIC_COMPRAR_TOKEN,
		NEXT_PUBLIC_CRIAR_PROJETO: NEXT_PUBLIC_CRIAR_PROJETO,
		NEXT_PUBLIC_GERAR_PIX: NEXT_PUBLIC_GERAR_PIX,
		NEXT_PUBLIC_GET_TRANSACTIONS: NEXT_PUBLIC_GET_TRANSACTIONS,
		NEXT_PUBLIC_GET_USER_CADASTRO: NEXT_PUBLIC_GET_USER_CADASTRO,
		NEXT_PUBLIC_GET_USER_INFO: NEXT_PUBLIC_GET_USER_INFO,
		NEXT_PUBLIC_PROJETO_ID_URL: NEXT_PUBLIC_PROJETO_ID_URL,
		NEXT_PUBLIC_PROJETO_URL: NEXT_PUBLIC_PROJETO_URL,
		NEXT_PUBLIC_REQUEST_LOGIN: NEXT_PUBLIC_REQUEST_LOGIN,
		NEXT_PUBLIC_SEND_EMAIL_APPROVED: NEXT_PUBLIC_SEND_EMAIL_APPROVED,
		NEXT_PUBLIC_SEND_EMAIL_FINISHED: NEXT_PUBLIC_SEND_EMAIL_FINISHED,
		NEXT_PUBLIC_SEND_EMAIL_REJECTED: NEXT_PUBLIC_SEND_EMAIL_REJECTED,
		NEXT_PUBLIC_UPLOAD_BACKGROUND_PHOTO: NEXT_PUBLIC_UPLOAD_BACKGROUND_PHOTO,
		NEXT_PUBLIC_UPLOAD_BACKGROUND_PROJETO:
			NEXT_PUBLIC_UPLOAD_BACKGROUND_PROJETO,
		NEXT_PUBLIC_UPLOAD_DOCUMENTS_PROJETO: NEXT_PUBLIC_UPLOAD_DOCUMENTS_PROJETO,
		NEXT_PUBLIC_UPLOAD_PROFILE_PHOTO: NEXT_PUBLIC_UPLOAD_PROFILE_PHOTO,
		NEXT_PUBLIC_VERIFICATION_ID_METAMAP: NEXT_PUBLIC_VERIFICATION_ID_METAMAP,
		NEXT_PUBLIC_VERIFICATION_USER: NEXT_PUBLIC_VERIFICATION_USER,
		NEXT_PUBLIC_ID_GOOGLE_ANALYTIC: NEXT_PUBLIC_ID_GOOGLE_ANALYTIC,
	};

	return {
		props: {
			envs,
		},
	};
}
export default appWithTranslation(App, nextI18NextConfig);
